import API from '@/axios'

const Endpoint = '/requisicoes/'
const ModelsEndpoint = '/models/'
const observations = '/observations/'
const examRequests = '/exam-requests/'
const requestedExams = '/requested-exams/'
const requestedExamGroups = '/requested-exam-groups/'
const status = '/status/'
const ids = 'ids/'
const avalaibleExams = '/available-exams/'
const avalaibleExamGroup = '/available-exam-groups'
const payers = '/payers'
const EndpointSearch = 'search?'
const EndpointAttachments = '/attachments'
const requestedForms = '/requested-exams-forms'
const EndpointSamples = '/samples'
const EndpointToRelate = '/to-relate'
const EndpointSampleExamsToRelate = '/requested-exams-to-relate'
const EndpointRelationships = '/relationships'

export default {
	async getObservations(request_id: string | number) {
		return await API.get(Endpoint + request_id + observations + '?page=1').then(
			(response) => response.data
		)
	},

	async getRequestedExams(request_id: string | number, page = 1, perPage = 15) {
		return await API.get(
			`${Endpoint}${request_id}${requestedExams}?page=${page}&perPage=${perPage}`
		).then((response) => response.data)
	},

	async getRequestedExamGroups(request_id: string | number, page = 1, perPage = 50) {
		return await API.get(Endpoint + request_id + requestedExamGroups, {
			params: { page, perPage },
		}).then((response) => response.data)
	},

	async getAvailableExams(
		request_id: string | number,
		{
			page = 1,
			search,
			showRepeated = undefined,
			hasAgreement = undefined,
			customerId,
		}: {
			page: number
			search?: string | null
			showRepeated?: boolean
			hasAgreement?: boolean
			customerId?: string | number
		}
	) {
		return await API.get(ModelsEndpoint + examRequests + request_id + avalaibleExams, {
			params: {
				search,
				page: page,
				limit: 5,
				showRepeated,
				hasAgreement,
				customer: customerId,
			},
		}).then((response) => response.data)
	},

	async getAvailableExamsGroups(
		request_id: string | number,
		{
			page,
			search,
			showRepeated = undefined,
		}: { page: number; search?: string | null; showRepeated?: boolean }
	) {
		return await API.get(Endpoint + request_id + avalaibleExamGroup, {
			params: {
				page,
				perPage: 5,
				search,
				showRepeated,
			},
		}).then((response) => response.data)
	},

	async createObservation(request_id: string | number, text: string) {
		return await API.post(Endpoint + request_id + observations, { text: text }).then(
			(response) => response.data
		)
	},

	async updateObservation(text: string, observationId: string | number) {
		return await API.patch(Endpoint + observations + observationId, { text: text }).then(
			(response) => response.data
		)
	},

	// Requests
	async getRequest(id: string | number) {
		return await API.get(Endpoint + id).then((response) => response.data)
	},

	async deleteObservation(observationId: string | number) {
		return await API.delete(Endpoint + observations + observationId).then(
			(response) => response.data
		)
	},

	async getRequests(page = 1) {
		return await API.get(Endpoint + '?page=' + page).then((response) => response.data)
	},

	async searchRequests({
		page = 1,
		perPage = 25,
		search,
		requestedExamStatus,
		requestedExamPriority,
		orderDirection,
		examRequestStatus,
		examRequestPriority,
		orderBy,
		createdAfter,
		createdBefore,
		orderId,
	}: {
		page: number
		perPage: number
		search?: string | null
		requestedExamStatus: any
		requestedExamPriority: any
		orderDirection: any
		examRequestStatus: any
		examRequestPriority: any
		orderBy: any
		createdAfter: any
		createdBefore: any
		orderId: string | number
	}) {
		return await API.get(Endpoint + EndpointSearch, {
			params: {
				page,
				perPage,
				search,
				requestedExamStatus,
				requestedExamPriority,
				orderDirection,
				examRequestStatus,
				examRequestPriority,
				orderBy,
				createdAfter,
				createdBefore,
				orderId,
			},
		}).then((response) => response.data)
	},

	async createRequest(payload: any) {
		return await API.post(Endpoint, payload).then((response) => response.data)
	},

	async updateRequest(id: string | number, payload: any) {
		return await API.patch(Endpoint + id, payload).then((response) => response.data)
	},

	async deleteRequest(id: string | number) {
		return await API.delete(Endpoint + id).then((response) => response.data)
	},

	async getPayers(id: string | number) {
		return await API.get(Endpoint + id + payers).then((response) => response.data)
	},

	async addPayer(id: string | number, payload: any) {
		return await API.post(Endpoint + id + payers, payload).then((response) => response.data)
	},

	async removePayer(id: string | number, payload: any) {
		return await API.delete(Endpoint + id + payers, { data: payload }).then(
			(response) => response.data
		)
	},

	async createRequestedExams(request_id: string | number, response: any) {
		return await API.post(Endpoint + request_id + requestedExams, response).then(
			(response) => response.data
		)
	},

	async createRequestedExamsAgreement(request_id: string | number, payload: any) {
		return await API.post(Endpoint + request_id + requestedExams, payload).then(
			(response) => response
		)
	},

	async updateRequestStatus(id: string | number, payload: any) {
		return await API.patch(Endpoint + id + '/' + status, payload).then(
			(response) => response.data
		)
	},

	async updateRequestedExams(id: string | number, payload: any) {
		return await API.patch(Endpoint + requestedExams + id, payload).then(
			(response) => response.data
		)
	},

	async updateStatusRequestedExams(id: string | number, status_id: any) {
		return await API.patch(Endpoint + requestedExams + id + '/status', status_id).then(
			(response) => response
		)
	},

	async deleteRequestedExams(
		request_id: string | number,
		requested_exams_ids: number[] | string[]
	) {
		return await API.delete(Endpoint + request_id + requestedExams, {
			data: { requested_exams_ids: requested_exams_ids },
		}).then((response) => response)
	},

	async getRequestStatusList() {
		return await API.get(Endpoint + status).then((response) => response.data)
	},

	async getRequestsId() {
		return await API.get(Endpoint + ids).then((response) => response.data)
	},

	async deleteRequestedExamGroups(
		request_id: string | number,
		requested_exams_group_ids: number[] | string[]
	) {
		return await API.delete(Endpoint + request_id + requestedExamGroups, {
			data: { exam_groups_ids: requested_exams_group_ids },
		}).then((response) => response)
	},

	async getAllExamsOrder(orderId?: string | number) {
		return await API.get(Endpoint + requestedExams + EndpointSearch, {
			params: {
				page: 1,
				perPage: 100,
				orderId,
			},
		}).then((response) => response.data)
	},

	async getAllExams(
		page = 1,
		perPage = 25,
		search?: string | null,
		requestedExamStatus?: any,
		requestedExamPriority?: any,
		orderDirection?: any,
		examRequestStatus?: any,
		examRequestPriority?: any,
		orderBy?: any,
		createdAfter?: any,
		createdBefore?: any,
		orderId?: string | number,
		groupBy?: string | null,
		customerId?: string | number,
		billed?: boolean,
		paid?: boolean
	) {
		return await API.get(Endpoint + requestedExams + EndpointSearch, {
			params: {
				page,
				perPage,
				search,
				requestedExamStatus,
				requestedExamPriority,
				orderDirection,
				examRequestStatus,
				examRequestPriority,
				orderBy,
				createdAfter,
				createdBefore,
				orderId,
				groupBy: groupBy,
				customerId: customerId ? customerId : undefined,
				billed,
				paid,
			},
		}).then((response) => response.data)
	},

	async searchAllExams({
		page = 1,
		perPage = 25,
		search,
		requestedExamStatus,
		requestedExamPriority,
		orderDirection,
		examRequestStatus,
		examRequestPriority,
		orderBy,
		createdAfter,
		createdBefore,
		orderId,
		groupBy,
		customerId,
		billed,
		paid,
	}: {
		page: number
		perPage: number
		search: string | null
		requestedExamStatus?: any
		requestedExamPriority?: any
		orderDirection?: any
		examRequestStatus?: any
		examRequestPriority?: any
		orderBy?: any
		createdAfter?: any
		createdBefore?: any
		orderId?: string | number
		groupBy?: string
		customerId?: number | string
		billed?: boolean
		paid?: boolean
	}) {
		return await API.get(Endpoint + requestedExams + EndpointSearch, {
			params: {
				page,
				perPage,
				search,
				requestedExamStatus,
				requestedExamPriority,
				orderDirection,
				examRequestStatus,
				examRequestPriority,
				orderBy,
				createdAfter,
				createdBefore,
				orderId,
				groupBy: groupBy,
				customerId: customerId ? customerId : undefined,
				billed,
				paid,
			},
		}).then((response) => response.data)
	},

	async getExamStatusList() {
		return await API.get(Endpoint + requestedExams + status).then((response) => response.data)
	},

	async getAttachmentsRequest({
		id,
		withRequestedExamForms = false,
		withSamples = false,
	}: {
		id: string | number
		withRequestedExamForms: boolean
		withSamples: boolean
	}) {
		return await API.get(Endpoint + id + EndpointAttachments, {
			params: {
				withRequestedExamForms,
				withSamples,
			},
		}).then((response) => response.data)
	},

	async createAttachment(payload: any) {
		return await API.post(Endpoint + EndpointAttachments, payload).then(
			(response) => response.data
		)
	},

	async getAttachmentDownload(uuid: string | number) {
		return await API.get(Endpoint + EndpointAttachments + `/${uuid}` + '/download', {
			responseType: 'blob',
		}).then((response) => response.data)
	},

	async removeAttachment(uuid: string | number) {
		return await API.delete(Endpoint + EndpointAttachments + `/${uuid}`).then(
			(response) => response
		)
	},

	async cancelReceiving(id: string | number, cancellation_reason: string) {
		return await API.delete(Endpoint + `/${id}`, { data: cancellation_reason }).then(
			(response) => response
		)
	},

	async cancelSample(id: string | number, cancellation_reason: string) {
		return await API.delete(Endpoint + `/samples/${id}`, { data: cancellation_reason }).then(
			(response) => response
		)
	},

	async getForms(id: string | number) {
		return await API.get(Endpoint + `${id}/` + requestedForms).then((response) => response)
	},

	async getSamples(id: string | number, page: number, perPage: number) {
		return await API.get(
			Endpoint + `${id}/` + EndpointSamples + `?page=${page}&perPage=${perPage || 15}`
		).then((response) => response)
	},

	async createSample(id: string | number, payload: any) {
		return await API.post(Endpoint + id + EndpointSamples, payload).then(
			(response) => response.data
		)
	},

	async editSample(id: string | number, payload: any) {
		return await API.patch(Endpoint + EndpointSamples + '/' + id, payload).then(
			(response) => response.data
		)
	},

	async removeSample(payload: any) {
		return await API.delete(Endpoint + EndpointSamples, { data: payload }).then(
			(response) => response.data
		)
	},

	async getSampleStatusList() {
		return await API.get(Endpoint + EndpointSamples + status).then((response) => response.data)
	},

	async changeSampleStatus(id: string | number, payload: any) {
		return await API.patch(Endpoint + EndpointSamples + `/${id}` + status, payload).then(
			(response) => response.data
		)
	},

	async getSamplesToRelate(id: string | number, page = 1, parentless: any) {
		return await API.get(Endpoint + id + EndpointSamples + EndpointToRelate, {
			params: {
				page,
				parentless,
			},
		}).then((response) => response.data)
	},

	async getSamplesExamsToRelate(id: string | number, page = 1) {
		return await API.get(Endpoint + id + EndpointSamples + EndpointSampleExamsToRelate, {
			params: {
				page,
			},
		}).then((response) => response.data)
	},

	async getSampleRelationShips({
		protocol_number,
		barcode_protocol_number,
	}: {
		protocol_number?: number | string | null
		barcode_protocol_number?: number | string | null
	}) {
		return await API.get(Endpoint + EndpointSamples + EndpointRelationships, {
			params: {
				protocol_number: protocol_number || undefined,
				barcode_protocol_number: barcode_protocol_number || undefined,
			},
		}).then((response) => response.data)
	},

	async getAllRequestedsExams({
		page,
		limit,
		customer_id,
		requestedExamStatus,
	}: {
		page: number
		limit: number
		customer_id?: number | string
		requestedExamStatus: any
	}) {
		return await API.get(Endpoint + requestedExams + 'search', {
			params: {
				page: page,
				perPage: limit,
				customerId: customer_id,
				billed: false,
				requestedExamStatus,
			},
		}).then((response) => response)
	},

	async handleFindEntity({
		entity_id,
		entity_type,
	}: {
		entity_id: string | number
		entity_type: string | null
	}) {
		return await API.get(Endpoint + `find-entity`, {
			params: {
				entity_id: entity_id,
				entity_type: entity_type,
			},
		}).then((response) => response)
	},

	async deleteMultipleRequestedExams(selectedExams: any) {
		return await API.delete(Endpoint + requestedExams + '/destroy-many', {
			data: {
				requested_exams_ids: selectedExams,
			},
		})
	},
}
