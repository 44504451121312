import authAPI from '@/modules/autenticacao/service'
import API from '../service'
import { types as errorTypes } from '@/modules/errors/store/types.ts'
import { types as successTypes } from '@/modules/successAlert/store/types.ts'
import { STATUS_SUCCESS, STATUS_NOT_FOUND } from '@/utils/constants/statusConstants'

export default {
	namespaced: true,

	state: {
		invites: [],
		usuarios: [],
		carregado: false,
		selecionado: {},
		usersRelationship: [],
	},

	getters: {
		getIndex: (state) => {
			return (id) => {
				return state.usuarios.findIndex((usuario) => usuario.id === id)
			}
		},

		getIndexInvite: (state) => {
			return (id) => {
				return state.invites.findIndex((invite) => invite.id === id)
			}
		},

		getIndexEmailInviteUsed: (state) => {
			return (email) => {
				return state.invites.findIndex((invite) => invite.email === email)
			}
		},

		getIndexEmail: (state) => {
			return (email) => {
				return state.usuarios.findIndex((usuario) => usuario.email === email)
			}
		},
	},

	mutations: {
		addInvite: (state, payload) => {
			state.invites.push(payload)
		},

		addMultipleInvites: (state, invites) => {
			state.invites = state.invites.concat(invites)
		},

		resetInvites: (state) => {
			state.invites = []
		},

		setUsers: (state, payload) => {
			state.usuarios = payload
			state.carregado = true
		},

		setProprietario: (state, email) => {
			// Chamado externamente [outro módulo]... (não faça isso em casa)
			const usuario = (email) => {
				return state.usuarios.findIndex((usuario) => usuario.email === email)
			}

			const proprietarioAtual = () => {
				return state.usuarios.findIndex((usuario) => usuario.vinculo.proprietario)
			}

			// Removendo propriedade atual
			const indexOwner = proprietarioAtual()
			const removeOwner = Object.assign(
				state.usuarios[indexOwner],
				(state.usuarios[indexOwner].vinculo.proprietario = false)
			)

			state.usuarios.splice(indexOwner, 1, removeOwner)

			// Adicionando nova propriedade
			const index = usuario(email)
			const update = Object.assign(
				state.usuarios[index],
				(state.usuarios[index].vinculo.proprietario = true)
			)

			state.usuarios.splice(index, 1, update)
		},

		resetCarregado: (state) => {
			state.carregado = false
			state.usuarios = []
		},

		setSelected: (state, payload) => (state.selecionado = payload),

		addUser: (state, payload) => state.usuarios.push(payload),

		updateUser: (state, { index, payload }) => state.usuarios.splice(index, 1, payload),

		deleteUser: (state, index) => state.usuarios.splice(index, 1),

		setUsersRelationship: (state, usersFound) =>
			(state.usersRelationship = Array.from(usersFound)),

		setInvites: (state, invites) => (state.invites = invites),

		deleteInvite: (state, index) => state.invites.splice(index, 1),

		addUserAttribute: (state, { indexUser, attribute }) => {
			state.usuarios[indexUser].attributes.push(attribute)
		},

		updateAllUserAttribute: (state, { attribute }) => {
			state.usuarios.forEach((user, index) => {
				const indexAttribute = user.attributes.findIndex(
					(attributeOld) => attributeOld.id === attribute.id
				)

				if (indexAttribute > -1) {
					state.usuarios[index].attributes.splice(indexAttribute, 1, attribute)
				}
			})
		},

		removeAllUserAttribute: (state, { id_attribute }) => {
			state.usuarios.forEach((user, index) => {
				const indexAttribute = user.attributes.findIndex(
					(attributeOld) => attributeOld.id === id_attribute
				)

				if (indexAttribute > -1) {
					state.usuarios[index].attributes.splice(indexAttribute, 1)
				}
			})
		},

		removeAllInviteAttribute: (state, { id_attribute }) => {
			state.invites.forEach((invite, index) => {
				const indexAttribute = invite.attributes.findIndex(
					(attributeOld) => attributeOld.id === id_attribute
				)

				if (indexAttribute > -1) {
					state.invites[index].attributes.splice(indexAttribute, 1)
				}
			})
		},

		removeUserAttribute: (state, { indexUser, indexAttribute }) => {
			state.usuarios[indexUser].attributes.splice(indexAttribute, 1)
		},
	},

	actions: {
		async validateEmailInvite(_, payload) {
			try {
				const { status, data } = await authAPI.validateEmailInvite(payload)

				if (status !== 200) {
					return { error: data.email[0] }
				}
			} catch (e) {
				return { error: 'Não foi possível validar o email.' }
			}
		},

		selectUser({ commit }, payload) {
			commit('setSelected', Object.assign({}, payload))
		},

		async getAll({ commit, state }) {
			try {
				if (!state.carregado) {
					const response = await API.getUsers()

					commit('setUsers', response)
				}

				return state.usuarios
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},

		async getAllInvites({ commit, state }) {
			try {
				const invites = await API.getInvites()

				if (invites && invites.length) {
					commit('setInvites', invites)
				}

				return state.invites
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},

		async createUser({ commit }, payload) {
			try {
				const { usuario } = await API.createUser(payload)

				commit('addUser', usuario)
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},

		async changeStatus({ commit }, id) {
			try {
				const response = await API.changeStatus(id)

				if (response) {
					return response.status
				}
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},

		async updateUser({ commit, getters }, payload) {
			try {
				const id = payload.id
				const { usuario } = await API.updateUser(payload, id)

				const index = getters.getIndex(id)

				commit('updateUser', { index, payload: usuario })
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},

		async deleteUser({ commit, getters }, id) {
			try {
				await API.deleteUser(id)

				const index = getters.getIndex(id)

				commit('deleteUser', index)
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},

		async deleteMultipleUsers({ commit, getters }, ids) {
			try {
				const response = await API.deleteMultipleUsers(ids)

				if (response) {
					ids.forEach((id) => {
						const index = getters.getIndex(id)

						if (index > -1) {
							commit('deleteUser', index)
						}
					})
				}

				return response
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},

		async searchUsers({ state, commit }, search) {
			try {
				const usersFound = await API.searchUsers(search)

				if (usersFound && usersFound.length) {
					const users = usersFound.filter((user) => {
						const userMember = state.usuarios.find(
							(userOld) => userOld.uuid === user.uuid
						)

						const userInvited = state.invites.find(
							(userInvite) => userInvite.uuid === user.uuid
						)

						return !userMember && !userInvited
					})

					commit('setUsersRelationship', users)
				}

				return true
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},

		async generateToken({ commit }) {
			try {
				const { token } = await API.generateToken()

				return token
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},

		async createInvites({ commit }, payload) {
			try {
				const { status, data: invites } = await API.submitInvites(payload)

				if (status === STATUS_SUCCESS) {
					commit('addMultipleInvites', invites)
				}

				return status === STATUS_SUCCESS
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},

		async confirmateInvite({ commit }, token) {
			try {
				const response = await API.confirmateInvite(token)

				const isValidStatus = !response.isAxiosError

				if (isValidStatus) {
					if (response.status === STATUS_SUCCESS) {
						commit(
							`Successes/${successTypes.ADD_SUCCESS}`,
							{
								title: 'Convite',
								description: 'O Convite foi aceito com sucesso.',
							},
							{ root: true }
						)

						return true
					}
				} else {
					if (response.response.status === STATUS_NOT_FOUND) {
						commit(
							`Errors/${errorTypes.ADD_ERROR}`,
							{ message: 'O Convite é Inválido!' },
							{ root: true }
						)
						return false
					}
				}
			} catch (errorResponse) {
				// console.log(errorResponse)
			}
		},

		async deleteInvite({ commit, getters }, id) {
			try {
				await API.deleteInvite(id)

				const index = getters.getIndexInvite(id)

				if (index > -1) {
					commit('deleteInvite', index)
				}
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},

		async deleteMultipleInvites({ commit, getters }, ids) {
			try {
				const response = await API.deleteMultipleInvites(ids)

				if (response) {
					ids.forEach((id) => {
						const index = getters.getIndexInvite(id)

						if (index > -1) {
							commit('deleteInvite', index)
						}
					})
				}

				return response
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},
	},
}
