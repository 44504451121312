import API from '../service'
import Orgaos_emissores from '../json/orgaos_emissores.json'
import Estados from '../json/estados.json'
import { types as errorTypes } from '@/modules/errors/store/types.ts'
import { types as successTypes } from '@/modules/successAlert/store/types.ts'
import i18n from '@/plugins/vue-i18n'
import { STATUS_CREATED, STATUS_SUCCESS } from '@/utils/constants/statusConstants'

export default {
	namespaced: true,

	state: {
		selecionado: {},
		carregado: false,
		clientes: [],
		paginas: 0,
		paginas_lidas: [],
		pagina_atual: 1,
		detalhesImportacao: [],
		// Importação de clientes
		fileParse: [],
		mapImport: {},
		importar: [],
		importar_erros: [],
		historicoImportacao: [],
		importacaoCarregado: false,
		// //////////////////////////////////////////////////
		clientsLaboratorieSelected: [],
		file: null,
		formFisico: {},
		formJuridico: {},
		clienteIndex: -1,
		fileContent: {
			values: null, // VALUES
			keys: null, // 1 KEYS - CAMPOS
		},
		showMessage: true,
		orgaos: Orgaos_emissores,
		estados: Estados,
		tiposPerfis: [
			{ id: 1, nome: 'tutor', texto: 'Tutor', translate: 'tutor' },
			{ id: 2, nome: 'medico', texto: 'Médico', translate: 'doctor' },
			{ id: 3, nome: 'clinica', texto: 'Clínica', translate: 'clinic' },
			// { id: 4, nome: 'laboratorio', texto: 'Laboratório', translate: 'laboratory' },
			{ id: 7, nome: 'naoInformado', texto: 'Não Informado', translate: 'uninformed' },
		],
	},

	getters: {
		getIndex: (state) => {
			return (uuid) => {
				return state.clientes.findIndex((cliente) => cliente.uuid === uuid)
			}
		},

		getIdState: (state) => {
			return (value) => {
				return state.estados.find((state) => state.nome === value)
			}
		},

		getIndexTag: (state) => {
			return ({ indexCustomer, idTag }) => {
				return state.clientes[indexCustomer].rotulos.findIndex((tag) => tag.id === idTag)
			}
		},

		getUuid: (state) => {
			return (uuid) => {
				return state.clientes.find((cliente) => cliente.uuid === uuid)
			}
		},

		getClientByDoctor: (state) => {
			return () => {
				return state.clientes.find((client) => client.tipo_de_cliente_id === 2)
			}
		},

		getIndexHistory: (state) => {
			return (id) => {
				return state.historicoImportacao.findIndex((history) => history.id === id)
			}
		},

		getCpfCnpj: (state) => {
			return (cpf_cnpj) => {
				return state.clientes.find((cliente) => cliente.cpf_cnpj === cpf_cnpj)
			}
		},

		getEmail: (state) => {
			return (email) => {
				return state.clientes.find((cliente) => cliente.email === email)
			}
		},

		file: (state) => {
			return state.file
		},
		fileParse: (state) => {
			return state.fileParse
		},
		fileContent: (state) => {
			return state.fileContent
		},
		showMessage: (state) => {
			return state.showMessage
		},
	},

	mutations: {
		setSelecionado: (state, payload) => (state.selecionado = Object.assign({}, payload)),
		setCarregado: (state, payload) => (state.carregado = payload),
		resetCarregado: (state) => {
			state.carregado = false
			state.clientes = []
		},
		resetHistoricoCarregado: (state) => {
			state.importacaoCarregado = false
			state.historicoImportacao = []
		},
		setImportacaoCarregado: (state, boolean) => (state.importacaoCarregado = boolean),
		setClientes: (state, payload) => (state.clientes = payload),
		setHistoricoImportacao: (state, payload) => (state.historicoImportacao = payload),
		setDetalheImportacao: (state, payload) => (state.detalhesImportacao = payload),
		addCliente: (state, payload) => state.clientes.push(payload),
		addHistoricoImportacao: (state, payload) => state.historicoImportacao.push(payload),
		updateCliente: (state, { index, cliente }) => {
			state.clientes.splice(index, 1, cliente)
			state.selecionado = Object.assign({}, cliente)
		},
		deleteCustomer: (state, index) => state.clientes.splice(index, 1),
		backPage: (state, payload) => (state.pagina_atual = payload),
		deselecionar: (state) => (state.selecionado = {}),
		setPage: (state, payload) => {
			const current_page = payload.current_page

			state.paginas_lidas[current_page] = payload
			state.pagina_atual = current_page
		},

		importPrepare: (state, { fields }) => {
			// Linhas do arquivo CSV
			for (const [i, item] of Object.entries(state.fileParse)) {
				const objeto = {}
				let erros = 0

				// Itens mapeados no formulário
				for (const [prop, value] of Object.entries(fields)) {
					const dados = { [prop]: item[value.field] } // Monta os elementos do payload a ser enviado para API

					Object.assign(objeto, dados)
				}

				if (!objeto.email) erros++
				if (!objeto.nome) erros++

				if (erros === 0) {
					state.importar.push(objeto)
				} else {
					if (!objeto.email && !objeto.nome) {
						// não faz nada com linha vazia
					} else {
						state.importar_erros.push(item) // Insere a linha do arquivo que contém erro(s)
					}
				}
			}
		},

		resetImport: (state) => {
			state.importar = []
			state.importar_erros = []
			state.mapImport = {}
			state.fileParse = []
		},

		/** Pega os campos e os valores */
		setFileParse: (state, payload) => {
			state.fileParse = payload
		},

		/** Arquivos selecionados no v-file input */
		updateFile(state, payload) {
			state.file = payload
		},

		updateShowMessage(state, payload) {
			state.showMessage = payload
		},

		/** Payload recebe um index do item para ser removido */
		remove: (state, payload) => {
			state.clientes.splice(payload, 1)
		},

		removeRotulo: (state, payload) => {
			const clienteIndex = state.clientes.findIndex((cliente) => cliente.id === payload.id)

			state.clientes[clienteIndex].rotulos.splice(payload.rotulo, 1)
		},

		adicionaRotulo: (state, payload) => {
			const clienteIndex = state.clientes.findIndex((cliente) => cliente.id === payload.id)

			state.clientes[clienteIndex].rotulos.push(payload.rotulo)
		},

		/** Define o tipo da pessoa */
		setTipoPessoa: (state, payload) => {
			state.tipoPessoa = payload
		},

		/** Reseta o arquivo inicial */
		resetFile(state) {
			state.file = null
		},

		deleteHistoryImport: (state, index) => state.historicoImportacao.splice(index, 1),

		updateRotuloSelected: (state, { index_cliente, index_cliente_rotulo, payload }) => {
			state.clientes[index_cliente].rotulos.splice(index_cliente_rotulo, 1, payload)
		},

		deleteRotuloSelected: (state, { index_cliente, index_cliente_rotulo }) => {
			state.clientes[index_cliente].rotulos.splice(index_cliente_rotulo, 1)
		},

		setClientsLaboratorieSelected: (state, clients) =>
			(state.clientsLaboratorieSelected = clients),

		addCustomerTag: (state, { indexCustomer, tag }) => {
			state.clientes[indexCustomer].rotulos.push(tag)
		},

		deselectCustomerTag: (state, { indexCustomer, indexTag }) => {
			state.clientes[indexCustomer].rotulos.splice(indexTag, 1)
		},

		deselectAllCustomerTag: (state, idTag) => {
			state.clientes.forEach((customer) => {
				const index = customer.rotulos.findIndex((tag) => tag.id === idTag)

				if (index > -1) {
					customer.rotulos.splice(index, 1)
				}
			})
		},

		updateAllCustomerTag: (state, { tag }) => {
			state.clientes.forEach((customer) => {
				const indexTag = customer.rotulos.findIndex((tagOld) => tagOld.id === tag.id)

				if (indexTag > -1) {
					customer.rotulos.splice(indexTag, 1, tag)
				}
			})
		},

		setBelongCustomer: (state, { index, payload }) =>
			state.clientes[index].linked_to_customers.push(payload),
	},

	actions: {
		async importClient({ state, dispatch, commit }) {
			try {
				const response = await API.importarClientes(
					state.file,
					state.importar,
					state.importar_erros
				)

				if (response.import.clientes.length) {
					const clientes = state.clientes
					const clientesSucesso = response.import.clientes

					clientesSucesso.forEach((cliente) => clientes.push(cliente))
					commit('setClientes', clientes)
					commit(
						`Successes/${successTypes.ADD_SUCCESS}`,
						{ title: i18n.t('modules.customers.feedback.success.create.importClient') },
						{ root: true }
					)
				}

				if (state.importacaoCarregado) {
					commit('addHistoricoImportacao', response.import)
				} else {
					dispatch('getHistoryImport')
				}

				return response
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},

		async getHistoryImport({ commit, state }) {
			try {
				if (!state.importacaoCarregado) {
					const response = await API.getHistoricoImportacao()

					commit('setHistoricoImportacao', response)
					commit('setImportacaoCarregado', true)
				}

				return state.clientes
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},

		async getAll({ commit, state }, deleted) {
			try {
				const response = await API.getClientes(deleted)

				commit('setClientes', response)

				return state.clientes
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},

		async restoreCustomer({ commit, state }, uuid) {
			try {
				const { status, data } = await API.restoreCustomer(uuid)

				return status === STATUS_SUCCESS ? data : false
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},

		// Buscar clientes de outras organizacoes
		async getClientsLaboratories({ commit, rootState }, organization) {
			try {
				const token = rootState.Auth.token.access_token
				const clients = await API.getClientsLaboratories(token, organization)

				if (clients && clients.length) {
					commit('setClientsLaboratorieSelected', clients)
				}

				return clients
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},

		async visualizaPerfil({ getters, commit }, payload) {
			try {
				const item = getters.getUuid(payload)
				const cliente = await API.visualizaCliente(item.uuid)

				commit('setSelecionado', cliente)

				return cliente
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},

		async visualizaHistoricoImportacao({ commit }, id) {
			try {
				const { registros } = await API.visualizaHistorico(id)

				commit('setDetalheImportacao', registros)
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},

		selecionar({ commit }, payload) {
			const cliente = Object.assign({}, payload)

			commit('setSelecionado', cliente)
		},

		async createCliente({ commit }, payload) {
			try {
				const response = await API.criarCliente(payload)

				if (response) {
					commit('addCliente', response)
					commit(
						`Successes/${successTypes.ADD_SUCCESS}`,
						{ title: i18n.t('modules.customers.feedback.success.create.client') },
						{ root: true }
					)
				}

				return response
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},

		async deleteCustomer({ commit, getters }, uuid) {
			try {
				const response = await API.deleteCustomer(uuid)

				if (response) {
					const index = getters.getIndex(uuid)

					commit(
						`Successes/${successTypes.ADD_SUCCESS}`,
						{ title: i18n.t('modules.customers.feedback.success.remove.client') },
						{ root: true }
					)
					commit('deleteCustomer', index)
				}

				return response
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},

		async updateCliente({ commit, getters }, { payload, uuid }) {
			try {
				const customer = await API.editarCliente(payload, uuid)

				if (customer) {
					const index = getters.getIndex(uuid)

					commit('updateCliente', { index, cliente: customer })
					commit(
						`Successes/${successTypes.ADD_SUCCESS}`,
						{ title: i18n.t('modules.customers.feedback.success.update.client') },
						{ root: true }
					)
				}

				return customer
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},

		async updateClientImage({ commit, getters }, { payload, uuid }) {
			try {
				const customer = await API.updateClientImage(payload, uuid)

				if (customer) {
					const index = getters.getIndex(uuid)

					commit('updateCliente', { index, cliente: customer })
					commit(
						`Successes/${successTypes.ADD_SUCCESS}`,
						{ title: i18n.t('modules.customers.feedback.success.update.client') },
						{ root: true }
					)
				}

				return customer
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},

		async updateEndereco({ commit, getters }, { uuid, endereco }) {
			try {
				const cliente = await API.editarCliente(endereco, uuid)
				const index = getters.getIndex(uuid)

				commit('updateCliente', { index, cliente })
				commit(
					`Successes/${successTypes.ADD_SUCCESS}`,
					{ title: i18n.t('modules.customers.feedback.success.update.client') },
					{ root: true }
				)
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},

		downloadCSV(_, payload) {
			const arquivo = document.createElement('a')

			arquivo.href = 'data:text/csv; charset=utf-8,' + encodeURI(payload)
			arquivo.target = '_blank'
			arquivo.download = 'erros_importacao.csv'
			arquivo.click()
		},

		downloadCSVPadrao(_, payload) {
			const arquivo = document.createElement('a')

			arquivo.href = 'data:text/csv; charset=utf-8,' + encodeURI(payload)
			arquivo.target = '_blank'
			arquivo.download = 'clustervet.csv'
			arquivo.click()
		},

		async searchClientes({ getters, commit }, payload) {
			try {
				const item = getters.getUuid(payload)

				return await API.visualizaCliente(item.uuid)
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},

		async deleteHistoryImport({ commit, getters }, id) {
			try {
				const response = await API.excluirHistoricoImportacao(id)
				const index = getters.getIndexHistory(id)

				commit('deleteHistoryImport', index)
				commit(
					`Successes/${successTypes.ADD_SUCCESS}`,
					{ title: i18n.t('modules.customers.feedback.success.remove.importHistory') },
					{ root: true }
				)
				return response
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},

		async validarCPF(_, cpf) {
			try {
				const response = await API.validarCPF(cpf)

				return { status: response.status }
			} catch (e) {
				return { status: e.response.status, data: e.response.data }
			}
		},

		async validarCNPJ(_, cnpj) {
			try {
				const response = await API.validarCNPJ(cnpj)

				return { status: response.status }
			} catch (e) {
				return { status: e.response.status, data: e.response.data }
			}
		},

		async validarEmail(_, email) {
			try {
				const response = await API.validarEmail(email)

				return { status: response.status }
			} catch (e) {
				return { status: e.response.status, data: e.response.data }
			}
		},

		async selectTag({ getters, commit }, { tag, customer }) {
			try {
				const response = await API.selectTag(tag.id, customer.id)

				if (response) {
					const indexCustomer = getters.getIndex(customer.uuid)

					if (indexCustomer > -1) {
						commit('addCustomerTag', { indexCustomer, tag })
					}
				}
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},

		async deselectTag({ getters, commit }, { tag, customer }) {
			try {
				const response = await API.deselectTag(tag.id, customer.id)

				if (response) {
					const indexCustomer = getters.getIndex(customer.uuid)

					if (indexCustomer > -1) {
						const indexTag = getters.getIndexTag({ indexCustomer, idTag: tag.id })

						if (indexTag > -1) {
							commit('deselectCustomerTag', { indexCustomer, indexTag })
						}
					}
				}
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},

		async getAddressZipCode({ commit }, cep) {
			try {
				return await API.getAddressZipCode(cep)
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},

		async createLinkedCustomer({ getters, commit }, { payload, customerUuid }) {
			try {
				const {
					status,
					data: { belongs_to },
				} = await API.createLinkedCustomer(payload)

				if (status === STATUS_CREATED) {
					const index = getters.getIndex(customerUuid)

					if (index > -1) {
						commit('setBelongCustomer', { index, payload: belongs_to })
					}
				}

				return status === STATUS_CREATED
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error.response, { root: true })
			}
		},
	},
}
