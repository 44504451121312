import { IPayloadUpdateExamTechnicalManager } from './../services/types'
import { IResponseEquipment } from '@/modules/equipments/store/types'
import { types as errorTypes } from '@/modules/errors/store/types'
import { IResponseForm } from '@/modules/forms/store/types'
import { IResponseMaterial } from '@/modules/materiais/service/types'
import { IResponseMethod } from '@/modules/methods/store/types'
import { types as successTypes } from '@/modules/successAlert/store/types'
import i18n from '@/plugins/vue-i18n'
import { RootState } from '@/store/types'
import { STATUS_CREATED, STATUS_SUCCESS } from '@/utils/constants/statusConstants'
import { ITypeOrmPaginationTemplate } from '@/utils/interfaces/typeormPagination'
import { AxiosError } from 'axios'
import { ActionTree } from 'vuex'
import API from '../services'
import {
	IPayloadExam,
	IPayloadUpdateExam,
	IPayloadUpdateExamEquipments,
	IPayloadUpdateExamForms,
	IPayloadUpdateExamMaterials,
	IPayloadUpdateExamMethods,
	IPayloadUpdateExamStatus,
	IResponseExam,
} from '../services/types'
import { ExamState, IExamFilters, actionTypes, mutationTypes } from './types'
import { DISABLE_LOADING, ENABLE_LOADING } from '@/utils/constants/generalizeConstants'

export const actions: ActionTree<ExamState, RootState> = {
	async [actionTypes.GET_EXAM]({ commit }, examId: number): Promise<IResponseExam | boolean> {
		try {
			commit(mutationTypes.SET_EXAM_LOADING, true)

			const { status, data: exam } = await API.getExam(examId)

			if (status === STATUS_SUCCESS) {
				commit(mutationTypes.UPDATE_SELECTED_EXAM, exam)
			}

			commit(mutationTypes.SET_EXAM_LOADING, false)

			return status === STATUS_SUCCESS ? exam : false
		} catch (error) {
			commit(mutationTypes.SET_EXAM_LOADING, false)
			commit(`Errors/${errorTypes.ADD_ERROR}`, error, { root: true })
			throw error
		}
	},

	async [actionTypes.GET_EXAMS](
		{ state, commit, getters },
		{ resetPage = false, filters }: { resetPage?: boolean; filters?: IExamFilters } = {}
	): Promise<ITypeOrmPaginationTemplate<IResponseExam> | boolean> {
		try {
			commit(mutationTypes.SET_EXAM_LOADING, true)

			if (resetPage) {
				commit(mutationTypes.SET_FILTERS, { ...state.filters, page: 1 })
			}

			const { status, data: exams } = await API.getExams(
				filters || getters.getFormattedFiltersWithoutVoids
			)

			if (status === STATUS_SUCCESS) {
				commit(mutationTypes.SET_EXAMS, exams)
			}

			commit(mutationTypes.SET_EXAM_LOADING, false)

			return status === STATUS_SUCCESS ? exams : false
		} catch (error) {
			commit(mutationTypes.SET_EXAM_LOADING, false)
			commit(`Errors/${errorTypes.ADD_ERROR}`, error, { root: true })
			throw error
		}
	},

	async [actionTypes.CREATE_EXAM](
		{ commit },
		payload: IPayloadExam
	): Promise<IResponseExam | boolean> {
		try {
			commit(mutationTypes.SET_EXAM_LOADING, true)

			const { status, data: exam } = await API.createExam(payload)

			if (status === STATUS_CREATED) {
				commit(mutationTypes.UPDATE_SELECTED_EXAM, exam)
			}

			commit(mutationTypes.SET_EXAM_LOADING, false)

			return status === STATUS_CREATED ? exam : false
		} catch (error) {
			commit(mutationTypes.SET_EXAM_LOADING, false)
			commit(`Errors/${errorTypes.ADD_ERROR}`, error, { root: true })
			throw error
		}
	},

	async [actionTypes.CLONE_EXAM]({ commit }, id: string): Promise<IResponseExam | boolean> {
		try {
			commit(mutationTypes.SET_EXAM_LOADING, true)

			const { status, data: exam } = await API.cloneExam(id)

			if (status === STATUS_CREATED) {
				commit(mutationTypes.UPDATE_SELECTED_EXAM, exam)
			}

			commit(mutationTypes.SET_EXAM_LOADING, false)

			return status === STATUS_CREATED ? exam : false
		} catch (error) {
			commit(mutationTypes.SET_EXAM_LOADING, false)
			commit(`Errors/${errorTypes.ADD_ERROR}`, error, { root: true })
			throw error
		}
	},

	async [actionTypes.UPDATE_EXAM](
		{ commit },
		payload: IPayloadUpdateExam
	): Promise<IResponseExam | boolean> {
		try {
			commit(mutationTypes.SET_EXAM_SAVING, true)
			const { status, data: exam } = await API.updateExam(payload)

			if (status === STATUS_SUCCESS) {
				commit(mutationTypes.UPDATE_SELECTED_EXAM, exam)
			}

			commit(mutationTypes.SET_EXAM_SAVING, false)

			return status === STATUS_SUCCESS ? exam : false
		} catch (error) {
			commit(mutationTypes.SET_EXAM_SAVING, false)
			commit(`Errors/${errorTypes.ADD_ERROR}`, error, { root: true })
			throw error
		}
	},

	async [actionTypes.UPDATE_EXAM_STATUS](
		{ commit },
		payload: IPayloadUpdateExamStatus
	): Promise<IResponseExam | boolean> {
		try {
			commit(mutationTypes.SET_EXAM_SAVING, true)

			const { status, data: exam } = await API.updateExamStatus(payload)

			if (status === STATUS_SUCCESS) {
				commit(mutationTypes.UPDATE_SELECTED_EXAM, exam)
			}

			commit(mutationTypes.SET_EXAM_SAVING, false)

			return status === STATUS_SUCCESS ? exam : false
		} catch (error) {
			commit(mutationTypes.SET_EXAM_SAVING, false)
			commit(`Errors/${errorTypes.ADD_ERROR}`, error, { root: true })
			throw error
		}
	},

	async [actionTypes.UPDATE_EXAM_FORMS](
		{ commit },
		payload: IPayloadUpdateExamForms
	): Promise<IResponseForm[] | boolean> {
		try {
			commit(mutationTypes.SET_EXAM_SAVING, true)

			const { status, data: forms } = await API.updateExamForms(payload)

			if (status === STATUS_SUCCESS) {
				commit(mutationTypes.UPDATE_EXAM_FORMS, forms)
			}

			commit(mutationTypes.SET_EXAM_SAVING, false)

			return status === STATUS_SUCCESS ? forms : false
		} catch (error) {
			commit(mutationTypes.SET_EXAM_SAVING, false)
			commit(`Errors/${errorTypes.ADD_ERROR}`, error, { root: true })
			throw error
		}
	},

	async [actionTypes.DELETE_EXAM](
		{ commit, getters },
		examId: number
	): Promise<boolean | AxiosError<{ id: string; message: string }>> {
		try {
			commit(mutationTypes.SET_EXAM_SAVING, true)

			const { status } = await API.deleteExam(examId)

			if (status === STATUS_SUCCESS) {
				const index = getters.getExamIndex(examId)

				commit(mutationTypes.DELETE_EXAM, index)

				commit(
					`Successes/${successTypes.ADD_SUCCESS}`,
					{
						title: i18n.t('modules.exams.feedback.success.remove.exam'),
					},
					{ root: true }
				)
			}

			commit(mutationTypes.SET_EXAM_SAVING, false)

			return status === STATUS_SUCCESS
		} catch (error) {
			commit(mutationTypes.SET_EXAM_SAVING, false)

			commit(`Errors/${errorTypes.ADD_ERROR}`, error, { root: true })
			throw error
		}
	},

	async [actionTypes.UPDATE_EXAM_EQUIPMENTS](
		{ commit },
		payload: IPayloadUpdateExamEquipments
	): Promise<IResponseEquipment[] | boolean> {
		try {
			commit(mutationTypes.SET_EXAM_SAVING, true)

			const { status, data: equipments } = await API.updateExamEquipments(payload)

			if (status === STATUS_SUCCESS) {
				commit(mutationTypes.UPDATE_EXAM_EQUIPMENTS, equipments)
			}

			commit(mutationTypes.SET_EXAM_SAVING, false)

			return status === STATUS_SUCCESS ? equipments : false
		} catch (error) {
			commit(mutationTypes.SET_EXAM_SAVING, false)
			commit(`Errors/${errorTypes.ADD_ERROR}`, error, { root: true })
			throw error
		}
	},

	async [actionTypes.UPDATE_EXAM_METHODS](
		{ commit },
		payload: IPayloadUpdateExamMethods
	): Promise<IResponseMethod[] | boolean> {
		try {
			commit(mutationTypes.SET_EXAM_SAVING, true)

			const { status, data: methods } = await API.updateExamMethods(payload)

			if (status === STATUS_SUCCESS) {
				commit(mutationTypes.UPDATE_EXAM_METHODS, methods)
			}

			commit(mutationTypes.SET_EXAM_SAVING, false)

			return status === STATUS_SUCCESS ? methods : false
		} catch (error) {
			commit(mutationTypes.SET_EXAM_SAVING, false)
			commit(`Errors/${errorTypes.ADD_ERROR}`, error, { root: true })
			throw error
		}
	},

	async [actionTypes.UPDATE_EXAM_MATERIALS](
		{ commit },
		payload: IPayloadUpdateExamMaterials
	): Promise<IResponseMaterial[] | boolean> {
		try {
			commit(mutationTypes.SET_EXAM_SAVING, true)

			const { status, data: materials } = await API.updateExamMaterials(payload)

			if (status === STATUS_SUCCESS) {
				commit(mutationTypes.UPDATE_EXAM_MATERIALS, materials)
			}

			commit(mutationTypes.SET_EXAM_SAVING, false)

			return status === STATUS_SUCCESS ? materials : false
		} catch (error) {
			commit(mutationTypes.SET_EXAM_SAVING, false)
			commit(`Errors/${errorTypes.ADD_ERROR}`, error, { root: true })
			throw error
		}
	},

	async [actionTypes.UPDATE_EXAM_TECHNICAL_MANAGER](
		{ commit },
		payload: IPayloadUpdateExamTechnicalManager
	): Promise<IResponseMaterial[] | boolean> {
		try {
			commit(mutationTypes.SET_EXAM_SAVING, ENABLE_LOADING)

			const { status, data: technicalManager } = await API.updateExamTechnicalManager(payload)

			if (status === STATUS_SUCCESS) {
				// console.log(technicalManager)
			}

			commit(mutationTypes.SET_EXAM_SAVING, DISABLE_LOADING)

			return status === STATUS_SUCCESS ? technicalManager : false
		} catch (error) {
			commit(mutationTypes.SET_EXAM_SAVING, DISABLE_LOADING)
			commit(`Errors/${errorTypes.ADD_ERROR}`, error, { root: true })
			throw error
		}
	},

	async [actionTypes.GET_EXAM_MODELS]({ commit }) {
		try {
			const { status, data: examModels } = await API.getExamModels()

			if (status === STATUS_SUCCESS) {
				commit(mutationTypes.SET_EXAM_MODELS, examModels)
			}

			return status === STATUS_SUCCESS
		} catch (error) {
			commit(`Errors/${errorTypes.ADD_ERROR}`, error, { root: true })
		}
	},

	async [actionTypes.USE_EXAM_MODEL]({ commit }, id: string) {
		try {
			const { status, data: exam } = await API.useExamModel(id)

			if (status === STATUS_CREATED) {
				if (exam) {
					commit(mutationTypes.ADD_EXAM, exam)

					commit(
						`Successes/${successTypes.ADD_SUCCESS}`,
						{
							title: i18n.t('modules.exams.feedback.success.create.exam'),
						},
						{ root: true }
					)
				}
			}

			return status === STATUS_CREATED ? exam : false
		} catch (error) {
			commit(`Errors/${errorTypes.ADD_ERROR}`, error, { root: true })
		}
	},

	async [actionTypes.SELECT_TAG]({ getters, commit }, { tag, exam }) {
		try {
			await API.selectTag(tag.id, exam.id)

			const index = getters.getExamIndex(exam.id)

			if (index > -1) {
				commit(mutationTypes.SELECT_TAG, { index, label: tag })
			}
		} catch (error) {
			commit(`Errors/${errorTypes.ADD_ERROR}`, error, { root: true })
		}
	},

	async [actionTypes.DESELECT_TAG]({ getters, commit }, { tag, exam }) {
		try {
			await API.deselectTag(tag.id, exam.id)

			const index = getters.getExamIndex(exam.id)

			if (index > -1) {
				const indexLabel = getters.getLabelIndex({ index, idTag: tag.id })

				if (indexLabel > -1) {
					commit(mutationTypes.DESELECT_TAG, { index, indexLabel })
				}
			}
		} catch (error) {
			commit(`Errors/${errorTypes.ADD_ERROR}`, error, { root: true })
		}
	},
}

export default { actions }
