import API from '../service'
import ErrorService from '@/error'
import { types as successTypes } from '@/modules/successAlert/store/types'
import i18n from '@/plugins/vue-i18n'
import { types as errorTypes } from '@/modules/errors/store/types'
import { mutationTypes } from '@/modules/authentication/store/types'
export default {
	namespaced: true,

	state: {
		cached: null,
		token: null,
		isLoggedIn: false,
		usuarioLogado: {},
		languageActive: null,
		permissions: [],
		telefones: [],
		enderecos: [],
		councils: [],
		langs: [
			{ flag: 'pt_br.png', text: 'Português', value: 'pt' },
			{ flag: 'en_us.png', text: 'English', value: 'en' },
			{ flag: 'es.png', text: 'Espanhol', value: 'es' },
		],
	},

	getters: {
		has_email: (state) => state.cached && state.cached.email,
	},

	mutations: {
		// Não usado
		setNovoCadastro: (state, payload) => {
			state.novo_cadastro = payload
		},

		setOrganizacao: (state, payload) => {
			state.organizacao = payload
		},

		setUsuarioLogado: (state, payload) => {
			state.usuarioLogado = payload
		},

		setAccessToken: (state, payload) => {
			state.token = payload
		},

		setIsLoggedIn: (state, boolean) => {
			state.isLoggedIn = boolean
		},

		setPhoto: (state, payload) => {
			state.usuarioLogado.avatar = payload
		},

		reset: (state) => {
			state.token = null
			state.usuarioLogado = {}
			state.isLoggedIn = false
			state.languageActive = null
		},

		// Revisar
		setTelefone: (state, payload) => {
			state.telefones.push(payload)

			if (state.telefones.length == 1) {
				state.telefones[0].principal = true
			}
		},

		// Revisar
		setEndereco: (state, payload) => {
			state.enderecos.push(payload)

			if (state.enderecos.length == 1) {
				state.enderecos[0].principal = true
			}
		},

		// Revisar
		removeTelefone: (state, payload) => {
			const index = state.telefones.findIndex((search) => search.numero === payload.numero)

			if (index > -1) {
				state.telefones.splice(index, 1)

				if (state.telefones.length == 1) {
					state.telefones[0].principal = true
				}
			}
		},

		// Revisar
		removeEndereco: (state, payload) => {
			const index = state.enderecos.findIndex((search) => search.cep === payload.cep)

			if (index > -1) {
				state.enderecos.splice(index, 1)

				if (state.enderecos.length == 1) {
					state.enderecos[0].principal = true
				}
			}
		},

		// Revisar
		editEndereco: (state, payload) => {
			const index = state.enderecos.findIndex((search) => search.cep === payload.cep)

			if (index > -1) {
				let principal = false

				if (state.enderecos[index].principal) {
					principal = true
				}

				if (principal) {
					const array = {
						bairro: payload.bairro,
						cep: payload.cep,
						cidade: payload.cidade,
						complemento: payload.complemento,
						logradouro: payload.logradouro,
						nome: payload.nome,
						numero: payload.numero,
						principal: true,
						uf: payload.uf,
					}

					state.enderecos[index] = Object.assign({}, array)
				} else {
					state.enderecos[index] = Object.assign({}, payload)
				}
			}
		},

		// Revisar
		setPrincipalTelefone: (state, payload) => {
			state.telefones.forEach((search) => {
				if (search.numero === payload.numero) {
					search.principal = true
				} else {
					search.principal = false
				}
			})
		},

		// Revisar
		setPrincipalEndereco: (state, payload) => {
			state.enderecos.forEach((search) => {
				if (search.cep === payload.cep) {
					search.principal = true
				} else {
					search.principal = false
				}
			})
		},

		setUsuario: (state, payload) => {
			const fields = state.cached

			Object.assign(fields, payload)

			state.cached = fields
		},

		setLead: (state, { email }) => {
			state.cached = { email: email }
		},

		finalizarCadastro: (state) => {
			state.cached = null
		},

		setCouncils: (state, councils) => {
			state.councils = councils
		},

		setPermissions: (state, permissions) => {
			state.permissions = permissions
		},

		setLanguage: (state, language) => (state.languageActive = language),

		setSignature: (state, file) => (state.usuarioLogado.signature = file),
	},

	actions: {
		async getMyProfile({ commit }) {
			try {
				const profile = await API.getMyPerfil()

				if (profile) {
					commit('setUsuarioLogado', profile)
					commit('setIsLoggedIn', true)
					commit(`Auth/${mutationTypes.SET_LOGGED_USER}`, profile, { root: true })
				}

				return profile
			} catch (e) {
				console.error(e)
			}
		},

		validarCNPJ: async (context, cnpj) => {
			try {
				const response = await API.validarCNPJ(cnpj)

				return { status: response.status }
			} catch (e) {
				return { status: e.response.status, data: e.response.data }
			}
		},

		validarCRMV: async (context, crmv) => {
			try {
				const response = await API.validarCRMV(crmv)

				return { status: response.status }
			} catch (e) {
				return { status: e.response.status, error: e.response.data.crmv[0] }
			}
		},

		validarTokenApi: async (context, token) => {
			return (await API.validarToken(token)).data
		},

		submitLoginGoogle: async ({ commit }, payload) => {
			const response = (await API.loginGoogle(payload)).data

			if (response.redirect) {
				commit('setLead', { email: response.email })
				return response.redirect
			} else if (response.token) {
				commit('setAccessToken', response.token.access_token)
				commit('setUsuarioLogado', response.usuario)
				commit('setIsLoggedIn', true)

				return 'painel'
			} else {
				return response
			}
		},

		submitLogin: async ({ commit }, payload) => {
			const response = (await API.login(payload)).data

			if ('status' in response) {
				return { error: response.message }
			}

			if ('token' in response) {
				commit('setAccessToken', response.token.access_token)
				commit('setUsuarioLogado', response.usuario)
				commit('setIsLoggedIn', true)

				return { redirect: 'painel' }
			}
		},

		submitRecuperar: async ({ commit }, payload) => {
			const response = await API.recuperarSenha(payload)

			if (response.status === 200) {
				return { success: response.data }
			} else {
				return { error: response.data.message }
			}
		},

		setLead: async ({ commit }, payload) => {
			try {
				const { status, data } = await API.validarEmail(payload)

				if (status === 200) {
					commit('setLead', { email: payload })
					return true
				} else {
					return { error: data.email[0] }
				}
			} catch (e) {
				// console.log(e)
			}
		},

		submitConvites: async ({ state }, usuarios) => {
			const token = state.novo_cadastro.token.access_token
			const organizacaa_id = state.novo_cadastro.usuario.organizacoes[0].id

			return (await API.enviarConvites(usuarios, token, organizacaa_id)).data
		},

		reenviarConfirmacao: async (context, payload) => {
			return (await API.confirmarConta(payload)).data
		},

		validarToken: async ({ commit }, payload) => {
			const { token, usuario, message } = (await API.confirmarToken(payload)).data

			if (!token) {
				return { error: message }
			} else {
				commit('setAccessToken', token.access_token)
				commit('setUsuarioLogado', usuario)

				if (usuario.organizacoes.length > 0) {
					commit('setOrganizacao', usuario.organizacoes[0])
				}

				return { redirect: 'painel' }
			}
		},

		submitRegister: async ({ state }) => {
			return await API.register(state.cached)
		},

		consultaCNPJ: async (context, cnpj) => {
			return (await API.consultaCNPJ(cnpj)).data
		},

		async consultaCPF(_, payload) {
			try {
				return await API.consultaCPF(payload)
			} catch (error) {
				return console.error(error)
			}
		},

		alterarSenha: async ({ commit }, payload) => {
			const { token, usuario, message } = (await API.alterarSenha(payload)).data

			if (payload.recuperar) {
				if (!token) {
					return { error: message }
				} else {
					commit('setAccessToken', token.access_token)
					commit('setUsuarioLogado', usuario)

					if (usuario.organizacoes && usuario.organizacoes.length > 0) {
						commit('setOrganizacao', usuario.organizacoes[0])
					}

					return { redirect: 'painel' }
				}
			} else {
				if (message !== undefined) return { message: message }
			}
		},

		async updateProfile({ commit }, payload) {
			try {
				const { status, data: profile } = await API.updateProfile(payload)
				const statusSuccess = 200

				if (status === statusSuccess) {
					commit('setUsuarioLogado', profile)

					commit(
						`Successes/${successTypes.ADD_SUCCESS}`,
						{ title: i18n.t('modules.account.feedback.success.update.profile') },
						{ root: true }
					)
				}

				return profile
			} catch (error) {
				commit(`Errors/${errorTypes.ADD_ERROR}`, error, { root: true })
			}
		},

		async getCouncils({ commit }) {
			const councils = await API.getCouncils()

			if (councils && councils.length) {
				commit('setCouncils', councils)

				return true
			}

			return false
		},

		async getPermissions({ commit }) {
			const permissions = await API.getPermissions()

			if (permissions && permissions.length) {
				commit('setPermissions', permissions)

				return true
			}

			return false
		},
	},
}
